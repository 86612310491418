import React from "react";
import { objects } from "./mixedMedia";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import { Context as ProductContext } from '../../context/ProductContext';
import { Context as ArtContext } from '../../context/ArtContext';
import { base_url } from '../../helpers/baseURL';
import HTMLReactParser from "html-react-parser";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Price from '../../helpers/price';
import { useAlert } from 'react-alert';
import Loader from '../../components/checkout/loader';
import Loading from "react-js-loader";
import Popup from "reactjs-popup";
import { MetaTags } from 'react-meta-tags';
import Share from "../../components/share/share";
import Tools from "../../components/tools/tools";

const MixedMediaDetails = () => {
    const history = useHistory()
    let params = useParams()
    const alert = useAlert()
    const { state, getMixedMediaDetails, getPolicy, getCare, addToWish, deleteFromWish, checkWish } = useContext(ProductContext);
    let AContext = useContext(ArtContext)
    const [policy, setpolicy] = useState('')
    const [care, setCare] = useState("")
    const [loader, setloader] = useState(false)
    const [details, setDetails] = useState()
    const [included, setIncluded] = useState(null)
    const [variation, setVariation] = useState()
    const [zoom, openZoom] = useState(false)
    const [add, openAdd] = useState(false)
    const [pin, changepin] = useState('')
    const [selected, setselected] = useState(0)
    const [pinstatus, setpinstatus] = useState(false)
    const [tab, settab] = useState("description")
    const [variation_id, setVariationId] = useState("")
    const [colour_name, setColorName] = useState("")
    const [hoveredframe, hoverframe] = useState("")
    const [popselected, setpopselected] = useState(0)
    const [listed, setListed] = useState()

    useEffect(() => {
        window.scrollTo(0, 0)
        if (localStorage.getItem("pincode")) {
            changepin(localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "")
            checkpin(localStorage.getItem("pincode"))
        }
        if (params.path) {
            getPolicy()
                .then(resp => {
                    setpolicy(resp?.data)
                })
                .catch(err => {
                    console.log("error in Return Policy", err)
                })
            getCare()
                .then(resp => {
                    let data = resp?.find(x => x.type === "mixed_care")?.data
                    console.log("response of Care in gallery wall", data)
                    setCare(data)
                })
                .catch(err => {
                    console.error("error in Care gallery wall", err)
                })
            getMixedMediaDetails(params.path)
                .then(res => {
                    setDetails(res.data)
                    checkWish(res.default?.id)
                        .then(resp => {
                            setListed(resp)
                        })
                        .catch(err => {
                            console.error("error on check in wish", err)
                        })
                    setIncluded(res.included)
                    setVariation(res.default)
                    setVariationId(res.default?.id)
                    if (res.default) {
                        let color = res.default?.relationships.field_color?.data ? res.included?.find(x => x.id === res.default.relationships.field_color.data.id) : ""
                        setColorName(color?.attributes.name)
                    }
                })
                .catch(err => {
                    if (err === "not found")
                        history.push("/404")
                    else
                        console.log("error", err)
                })
        }
    }, [params.path])
    const checkpin = (code) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (code === '' || re.test(code)) {
            let element = document.getElementById("pincode-input")
            if (element)
                element.classList.remove("error")
            changepin(code)
        }
        if (code.length === 6) {
            axios({
                method: 'get',
                url: base_url + '/jsonapi/taxonomy_term/pincode?filter[productFilter][condition][path]=name&filter[productFilter][condition][value]=' + code
            })
                .then(res => {
                    if (res.data.data.length) {
                        setpinstatus(true)
                        localStorage.setItem("pincode", code)
                    }
                    else {
                        setpinstatus(false)
                    }
                })
                .catch(err => {
                    console.log(err.code)
                })
        }
        else {
            setpinstatus(false)
        }
    }

    const checkAdd = () => {
        if (!pinstatus) {
            let element = document.getElementById('pincode-input');
            element.classList.add('error');
            element.focus()
        }
        else
            setDataMixedMedia()
    }
    const setDataMixedMedia = () => {
        let galleryData = {
            "mixed_media_varid": variation?.id,
            "title": details?.attributes.title,
            "item_name": details?.attributes.title,
            "item_id": details?.relationships.default_variation.data ? included?.find(x => x.id === details?.relationships.default_variation.data.id)?.id : "",
            "total_price": variation?.attributes.price?.number ? Number(variation?.attributes.price?.number) : 0,
            "mixed_media_id": details?.id ? details.id : "",
            "mixed_media_color": colour_name,
            "image_id": variation.relationships.field_image?.data.length ? included.find(x => x.id === variation.relationships.field_image?.data[0].id)?.relationships.field_media_image?.data?.id : "",
            "dimension": details?.attributes.field_size
        }
        let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
        let cartData = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : []
        if (cartData) {
            let checkoutdata = localStorage.getItem("checkout_data") ? JSON.parse(localStorage.getItem("checkout_data")) : { total_no: 0 }
            if (Number(checkoutdata?.total_no) == 29) {
                alert.show("Maximum Cart Limit is 30")
                console.log("maximum achieved")
            }
            if (Number(checkoutdata.total_no) < 30) {
                if (cartData.findIndex(x => x.mixed_media_varid == galleryData.mixed_media_varid) >= 0) {
                    let i = cartData.findIndex(x => x.mixed_media_varid == galleryData.mixed_media_varid)
                    if (cartData[i].quantity)
                        cartData[i].quantity = Number(cartData[i].quantity) + 1
                    else
                        cartData[i].quantity = 2
                }
                else {
                    cartData.push(galleryData)
                }
                localStorage.setItem("cartData", JSON.stringify(cartData));
                if (checkoutdata) {
                    checkoutdata.total_no = checkoutdata.total_no ? checkoutdata.total_no + 1 : 1
                    checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
                    checkoutdata.total_price = checkoutdata.total_price + galleryData.total_price
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    AContext.cartTrig(AContext.state.cartTrigger + 1)
                }

                else {
                    let checkoutdata = {
                        "total_no": 1,
                        "total_price": galleryData.total_price
                    }
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    AContext.cartTrig(AContext.state.cartTrigger + 1)
                }
                setloader(false)
                openAdd(true)
            }
            else
                alert.show("Maximum Cart limit reached")
        }
        setloader(false)
    }
    const settingVariation = (id, name) => {
        let variation = included?.find(x => x.id === id)
        console.log("calling setting variation", variation)
        setselected(0)
        setpopselected(0)
        setVariationId(id)
        setColorName(name)
        setVariation(variation)
    }
    let touchStartX = 0;
    let touchEndX = 0;

    const handleTouchStart = (e) => {
        touchStartX = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        touchEndX = e.changedTouches[0].clientX;
        const swipeDistance = touchEndX - touchStartX;

        if (swipeDistance > 0) {
            if (selected !== 0) {
                setselected(selected - 1)
                setpopselected(selected - 1)
            }
        } else if (swipeDistance < 0) {
            if ((selected + 1) < variation?.relationships.field_image?.data.length) {
                setselected(selected + 1)
                setpopselected(selected + 1)
            }
        }
    };
    const addWish = () => {
        let id = details.relationships.default_variation?.data?.id
        setloader(true)
        addToWish(id)
            .then(res => {
                if (res === "item added") {
                    checkWish(id)
                        .then(resp => {
                            setListed(resp)
                            setloader(false)
                            alert.show("Added to Wishlist", { type: "success" });
                        })
                        .catch(err => {
                            console.error("error on check in wish", err)
                        })
                }
            })
            .catch(err => {
                if (err === "logged_out") {
                    alert.show("Login to add to wishlist")
                }
                console.log('error on adding to wishlist', err)
                setloader(false)
            })
    }
    const deleteWish = (id) => {
        let var_id = details.relationships.default_variation?.data?.id
        setloader(true)
        deleteFromWish(id)
            .then(res => {
                if (res === "item deleted") {
                    checkWish(var_id)
                        .then(resp => {
                            setListed(resp)
                            setloader(false)
                            alert.show("Removed from Wishlist", { type: "success" });
                        })
                        .catch(err => {
                            console.error("error on check in wish", err)
                        })
                }
            })
            .catch(err => {
                setloader(false)
            })
    }
    return (
        <div className="mixed-media-details">
            <Header />
            {loader ? <Loader /> : ''}
            {details ? <MetaTags>
                <meta name="description" content={`Create a statement design with ${details.attributes.title} Mixed Media Art by Espravo. Shop Now & Get Free Shipping!`} />
                {details.attributes.metatag_normalized.map((item) => {
                    if (item.tag === "meta")
                        if (item.attributes.name === "title")
                            return (
                                <title>Buy {details.attributes.title} | Mixed Media Art by Espravo</title>
                            )
                        else
                            return <meta name={`${item.attributes.name}`} content={`${item.attributes.content}`} />
                    else if (item.tag === "link") {
                        if (item.attributes?.rel === "canonical")
                            return <link rel={`${item.attributes.rel}`} href={`${window.location.href}`} />
                        else
                            return <link rel={`${item.attributes.rel}`} href={`${item.attributes.href}`} />
                    }
                })}
            </MetaTags> : ""}
            <div className="header-container pt-3 pb-5">
                <Link className="mb-1 selectBack brandon d-flex align-items-center" to={localStorage.getItem("mixed_media_back_path") ? localStorage.getItem("mixed_media_back_path") : `/mixed-media/`}>
                    <img src="/assets/arrow-left.png" className="selectBackIcon" alt="thumb"></img>
                    <p className='ml-2'>Back</p>
                </Link>
            </div>
            <div className="container">
                <div className="col-12 px-0 mx-auto">
                    {details ? <div className="row pt-m-5 pt-0 pb-5">
                        <div className="col-sm-1 thumb-cont desk-only">
                            {variation?.relationships.field_image.data?.map((img, ind) => {
                                let media = included?.find(x => x.id === img.id)?.relationships.thumbnail.data?.id
                                let image = included?.find(x => x.id === media)?.attributes.image_style_uri.find(x => x.thumbnail)?.thumbnail
                                return <div onClick={() => { setselected(ind); setpopselected(ind) }} className={`${selected === ind && !zoom ? "active " : ""}thumb p-1 mb-2`}>
                                    <img src={image ? image : "/assets/images/thumb1.png"} alt="thumb" />
                                </div>
                            })}
                        </div>
                        <div className="col-sm-5 col-6 pl-sm-0 col-12 position-relative">
                            <div className=" select-carou">
                                <div className="col-12 mx-md-auto px-0 px-md-3 mb-4 mb-md-0" onTouchStart={handleTouchStart}
                                    onTouchEnd={handleTouchEnd}>
                                    {variation?.relationships.field_image?.data.filter((x, i) => i === selected).map(img => {
                                        let media = included?.find(x => x.id === img.id)?.relationships.thumbnail.data?.id
                                        let image = included?.find(x => x.id === media)?.attributes.image_style_uri.find(x => x.inner)?.inner
                                        let pin_image = base_url + included?.find(x => x.id === media)?.attributes.uri.url
                                        return <>
                                            <img className="gallery-img w-100" src={image} />
                                            <Tools title={details?.attributes.title} addWish={addWish} deleteWish={deleteWish} listed={listed} openZoom={openZoom} image={pin_image} />
                                        </>
                                    })}
                                    {variation?.relationships.field_image?.data.length == 0 ?
                                        <h4 className="no-preview brandon">No Preview Available</h4>
                                        : ""}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center thumb-cont d-sm-none">
                                {variation?.relationships.field_image.data?.map((img, ind) => {
                                    let media = included?.find(x => x.id === img.id)?.relationships.thumbnail.data?.id
                                    let image = included?.find(x => x.id === media)?.attributes.image_style_uri.find(x => x.thumbnail)?.thumbnail
                                    return <div onClick={() => { setselected(ind); setpopselected(ind) }} className={`${selected === ind && !zoom ? "active " : ""}thumb p-1`}>
                                        <img src={image ? image : "/assets/images/thumb1.png"} alt="thumb" />
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2 mt-sm-0 col-12">
                            <h1 className="main-heading mt-3 mt-sm-0 ">
                                {details?.attributes.title}
                            </h1>
                            <div className="gallery-details my-2">
                                <div className="brandon mb-2 d-flex">
                                    <p className="title">Size: </p>
                                    <p className="detail pl-sm-3 pl-2">{details?.attributes.field_size ? details.attributes.field_size : ""}</p>
                                </div>
                                <div className="brandon mb-2 d-flex">
                                    <p className="title">No of Frames: </p>
                                    <p className="detail pl-sm-3 pl-2">{details?.attributes.field_no_of_frames ? details?.attributes.field_no_of_frames : ""}</p>
                                </div>
                                <div className="brandon mb-2 d-flex">
                                    <p className="title">Frame: </p>
                                    <p className="detail pl-sm-3 pl-2">{variation?.attributes.field_frame ? variation.attributes.field_frame : ""}</p>
                                </div>
                                <div>
                                    <h4 className="second-heading">Color</h4>
                                    {variation_id ? <p className="brandon sub-title mt-1 px-0 text-left">
                                        Selected Color: {colour_name}
                                    </p> : ""}
                                    <div className="d-flex flex-wrap mt-sm-3  frame-select-container">
                                        {details?.relationships.variations?.data?.length ?
                                            details?.relationships.variations?.data.filter(f => {
                                                const foundItem = included?.find(x => x.id === f.id);
                                                return foundItem?.attributes.status
                                            }).map((variation, ind) => {
                                                let detail = included?.find(x => x.id === variation.id)
                                                let color = detail?.relationships.field_color.data ? included?.find(x => x.id === detail.relationships.field_color.data.id) : ""
                                                let color_image = color?.relationships?.field_colour?.data ? included?.find(x => x.id === color.relationships.field_colour.data.id)?.attributes.uri.url : ""
                                                return <div className={`frame-select-box-container ${variation_id === variation.id ? "active" : ""}`} onMouseEnter={() => hoverframe(variation.id)} onMouseLeave={() => hoverframe("")} onClick={() => { settingVariation(variation.id, color?.attributes.name); }}>
                                                    <img className="frame-thumbnail" src={color_image ? base_url + color_image : ""} />
                                                    {hoveredframe === variation.id ? <div className="frame-hover-box brandon">
                                                        <img className="frame-hover-box-arrow" src="/assets/caption-box-arrow.png" />
                                                        <div className="brandon text">{color?.attributes.name}</div>
                                                    </div> : ""}
                                                </div>
                                            }) : <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={15} />}
                                    </div>
                                </div>
                            </div>
                            <div className="input-pin">
                                {/* <p className="select-title">Select Location For Delivery</p> */}
                                <input id="pincode-input" value={pin} className="brandon pincode-input" onChange={(e) => checkpin(e.target.value)} placeholder="Enter Pincode For Delivery Availability" />
                            </div>
                            {
                                pin.length ? pin.length === 6 ?
                                    <p className={pinstatus && pin.length === 6 ? "green" : "red"}>{pinstatus && pin.length === 6 ? "Delivery in 10-14 days" : "Delivery Unavailable"}</p>
                                    : '' : ''
                            }
                            <div className="price_cart d-flex align-items-center w-sm-80 w-100">
                                <p className="frame-price add-price font-weight-bold brandon">
                                    {variation?.attributes.price ? <span>Rs. <Price value={variation?.attributes.price.number} /></span> : ''}
                                </p>
                                <div onClick={() => checkAdd()} className="add-cart-button brandon">
                                    ADD TO CART
                                </div>
                            </div>
                            <div className="brandon mt-2 w-sm-80 w-100">
                                <div className="d-flex tab">
                                    <p className="description pb-1" style={{ borderBottom: tab === "description" ? "1.5px solid #1D1F22" : "", color: tab === "description" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("description")}>Description</p>
                                    {/* <p className="description ml-3 pb-1" style={{ borderBottom: tab === "review" ? "1.5px solid #1D1F22" : "", color: tab === "review" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("review")}>Read reviews</p> */}
                                    <p className="description ml-3 pb-1" style={{ borderBottom: tab === "care" ? "1.5px solid #1D1F22" : "", color: tab === "care" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("care")}>Care Instructions</p>
                                    <p className="description ml-3 pb-1" style={{ borderBottom: tab === "return" ? "1.5px solid #1D1F22" : "", color: tab === "return" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("return")}>Return Policy</p>
                                </div>
                                <div className="pl-1 py-2">
                                    {tab == "description" ? <p className="">{details?.attributes.body ? details?.attributes.body.value ? HTMLReactParser(details.attributes.body.value) : "No description" : ""}</p> : ""}
                                    {tab === "review" ? <p>There are no review for this products</p> : ""}
                                    {tab === "care" ? <p>{HTMLReactParser(care.attributes.body ? care.attributes.body.value : "NO care instructions for this product")}</p> : ""}
                                    {tab === "return" ? <p>{HTMLReactParser(policy.attributes.body ? policy?.attributes.body.value : "")}</p> : ""}
                                </div>
                            </div>
                        </div>


                    </div> : <div className='container py-5 my-5'> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>
                    }
                </div>
                {zoom ? <div className='overlay zoom-image-overlay'></div> : ""}
                <Popup className="zoompopup" open={zoom} onClose={() => openZoom(false)}>
                    <div className="d-flex zoompopup-container flex-column">
                        <img className="zoom-close cursor ml-auto mb-3 d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openZoom(false)} />
                        <div className="d-flex align-items-center popup-slider">
                            <div className="arrow-container">
                                <img className={`arrow larrow ${popselected === 0 ? "d-none" : ""}`} onClick={() => popselected !== 0 ? setpopselected(popselected - 1) : ""} src="/assets/larrow.png" />
                            </div>
                            {variation?.relationships.field_image?.data.filter((x, i) => i === popselected).map(img => {
                                let media = included?.find(x => x.id === img.id)?.relationships.thumbnail.data?.id
                                let image = included?.find(x => x.id === media)?.attributes.uri?.url
                                return <img className="gallery-img zoom-img" src={base_url + image} />
                            })}
                            <div className="arrow-container">
                                <img className={`arrow rarrow ${popselected === (variation?.relationships.field_image?.data.length - 1) ? "d-none" : ""}`} onClick={() => popselected !== (variation?.relationships.field_image?.data.length - 1) ? setpopselected(popselected + 1) : ""} src="/assets/rarrow.png" />
                            </div>
                        </div>
                    </div>
                </Popup>
                <Popup open={add} onClose={() => openAdd(false)}>
                    <div className="alert-popup">
                        <img className="zoom-close cursor ml-auto d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openAdd(false)} />
                        <div className="GT alert-heading">
                            Added to cart
                        </div>
                        <p className="brandon alert-desc">
                            Product successfully added to your shopping cart.
                        </p>
                        <div className="d-flex">
                            <div className="alert-continue cursor py-2 brandon" onClick={() => openAdd(false)}>Continue shopping</div>
                            <div className="alert-proceed py-2 cursor brandon" onClick={() => { history.push('/checkout/shopping-bag/'); }}>Proceed to checkout</div>
                        </div>
                    </div>
                </Popup>
            </div>
            <Footer />
        </div >
    )
}
export default MixedMediaDetails;